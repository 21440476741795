.root {
  width: 960px;
  background-color: #1e1e1e;
  padding: 32px 40px;
}

.section {
  background-color: #ffffff;
  border-radius: 4;
  margin: 4px 0px;
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.grey {
  background-color: #d1d1d1;
}

.pink {
  background-color: #ea205a;
  color: #fff;
}

.amountRequestedSection {
  background: white;
  border-left: 25px solid #1e979c;
  border-right: 25px solid #1e979c;
  font-size: 50px;
  text-align: center;
  font-weight: bold;
}

.image {
  width: 100%;
  margin-bottom: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.amount {
  font-size: 48px;
  font-weight: bold;
  margin-left: 32px;
  margin-right: 32px;
}

.noSelect {
  user-select: none;
}

.pointsExplanation {
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.cancelButton {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
